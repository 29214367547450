import { lazy } from 'react';
import WithSuspense from '../components/Loaders/WithSupense';
import { PRIVATE_PATHS, PUBLIC_PATHS } from './constants';
import { Navigate } from 'react-router-dom';
const { DASHBOARD, BITCOIN, AIRDROPS, US_DOLLAR, PRICES } =
  PRIVATE_PATHS;

const {
  LOGIN,
  REGISTER,
  FORGOT_PASS,
  VERIFY_PASS_OTP,
  VERIFY_EMAIL,
  RESET_PASS,
  LANDING,
  ABOUT,
  WALLET,
} = PUBLIC_PATHS;

const Login = WithSuspense(lazy(() => import('../pages/Authentication/Login')));
const Register = WithSuspense(
  lazy(() => import('../pages/Authentication/Register'))
);
const VerifyEmail = WithSuspense(
  lazy(() => import('../pages/Authentication/VerifyEmail'))
);
const ForgotPassword = WithSuspense(
  lazy(() => import('../pages/Authentication/ForgotPassword'))
);

const VerifyPass = WithSuspense(
  lazy(() => import('../pages/Authentication/VerifyPass'))
);

const ResetPassword = WithSuspense(
  lazy(() => import('../pages/Authentication/ResetPassword'))
);
const Landing = WithSuspense(
  lazy(() => import('../pages/Authentication/Landing'))
);

const Prices = WithSuspense(lazy(() => import('../pages/Prices/Prices')));
const Wallet = WithSuspense(
  lazy(() => import('../pages/Authentication/Wallet'))
);

const Bitcoin = WithSuspense(lazy(() => import('../pages/Payments/Bitcoin')));
const UsDollar = WithSuspense(lazy(() => import('../pages/Payments/UsDollar')));

const Airdrops = WithSuspense(lazy(() => import('../pages/Airdrops/Airdrops')));

const About = WithSuspense(lazy(() => import('../pages/Authentication/About')));

export const PRIVATE_ROUTES = [
  { path: DASHBOARD, element: <UsDollar /> },
  { path: BITCOIN, element: <Bitcoin /> },
  { path: US_DOLLAR, element: <UsDollar /> },
  { path: AIRDROPS, element: <Airdrops /> },
  { path: PRICES, element: <Prices /> },

  { path: '*', element: <Navigate to="/us-dollar" replace /> },
];

export const PUBLIC_ROUTES = [
  { path: REGISTER, element: <Register /> },
  { path: LOGIN, element: <Login /> },
  { path: VERIFY_EMAIL, element: <VerifyEmail /> },
  { path: VERIFY_PASS_OTP, element: <VerifyPass /> },
  { path: FORGOT_PASS, element: <ForgotPassword /> },
  { path: RESET_PASS, element: <ResetPassword /> },
  { path: WALLET, element: <Wallet /> },
  { path: LANDING, element: <Landing /> },
  { path: WALLET, element: <Wallet /> },
  { path: ABOUT, element: <About /> },

  { path: '*', element: <Navigate to="/landing" replace /> },
];
