import axios from 'axios';
import * as API from '../url';
import axiosInstance from '../axiosInstance';

export const getUser = async () => {
  const res = await axiosInstance.get(API.GET_USER);
  return res.data;
};

export const getTotalFunds = async () => {
  const res = await axiosInstance.get(API.GET_TOTAL_FUNDS);
  return res.data;
};

export const sendWithdrawFund = async (body) => {
  const res = await axiosInstance.post(API.SEND_WITHDRAW_FUND, body);
  return res.data;
};