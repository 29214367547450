import { useMutation, useQuery } from 'react-query';
import * as queryKey from '../queryKeys';
import { getTotalFunds, getUser, sendWithdrawFund } from '../api/account';


export const useGetUser = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(queryKey.GET_USER, getUser, {
    ...options,
  });

  return { data, isLoading, refetch };
};
export const useGetTotalFunds = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(queryKey.GET_TOTAL_FUNDS, getTotalFunds, {
    ...options,
  });

  return { data, isLoading, refetch };
};

export const useSendWithdrawFund = (options = {}) => {
  const { mutate, isLoading } = useMutation(sendWithdrawFund, {
    mutationKey: queryKey.SEND_WITHDRAW_FUND,
    ...options,
  });

  return { mutate, isLoading };
};