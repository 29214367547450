import { Box, useMediaQuery } from '@chakra-ui/react';
import Header from './AuthLayout/Header';
import SideBar from './AuthLayout/SideBar';

export const AuthLayout = ({ children }) => {
  const [isMobile] = useMediaQuery('(max-width: 999px)');
  return (
    <Box
      className="font-sans"
      fontFamily=" Urbanist"
      overflowX={isMobile ? 'hidden' : ''}
      bg="#fff"
      position="relative"
    >
      <Box minH="100vh" overflowX="hidden">
        <Header />
        {!isMobile && (
          <Box>
            <SideBar />
          </Box>
        )}

        <Box
          w="100%"
          pt={isMobile ? '120px' : '125px'}
          minH="100vh"
          pl={!isMobile ? '340px' : '20px'}
          pr={!isMobile ? '45px' : '20px'}
          pb={10}
        >
          {children}
        </Box>
      </Box>
      {/* <Box minH="100vh" overflowX="hidden">
        <Header />

        <Box
          w="100%"
          // pt={isMobile ? '120px' : '125px'}
          minH="100vh"
          //   pl={!isMobile ? '340px' : '20px'}
          //   pr={!isMobile ? '45px' : '20px'}
          //   pb={10}
        >
          {children}
        </Box>
      </Box> */}
    </Box>
  );
};

export const NonAuthLayout = ({ children }) => {
  return <div className="font-sans">{children}</div>;
};
