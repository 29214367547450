import {
  Box,
  Flex,
  Text,
  VStack,
  Collapse,
  Image,
  useMediaQuery,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  CloseButton,
} from '@chakra-ui/react';

import { sideBarData as siderBarData } from '../../common/constant';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { IoIosArrowDropdown } from 'react-icons/io';
import { ImPriceTags } from 'react-icons/im';
import { currencyFormat, useLogOut } from '../../../utils/helper';
import { IoMdLogOut } from 'react-icons/io';
import { Spinner } from '@chakra-ui/react';
import { useGetTotalFunds } from '../../../services/query/account';

const SideDrawer = ({ isOpen, onClose }) => {
  const logout = useLogOut();
  const [isLoading, setIsLoading] = useState(false);

  const [isMobile] = useMediaQuery('(max-width: 999px)');
  const action = () => {
    setIsLoading(true);
    setTimeout(() => {
      logout();
      setIsLoading(false);
    }, 1000);
  };
  const handleToggleSubItem = (name) => {
    setOpenSubItems((prevState) => {
      const newOpenSubItems = {};

      Object.keys(prevState).forEach((item) => {
        newOpenSubItems[item] = false;
      });

      const activeParentItem = siderBarData.find((item) =>
        pathname.includes(item.path)
      )?.name;

      newOpenSubItems[activeParentItem] = true;

      if (name) newOpenSubItems[name] = !prevState[name];

      return newOpenSubItems;
    });
  };
  const navigate = useNavigate();
  const [openSubItems, setOpenSubItems] = useState({});
  const { pathname } = useLocation();
  const { data, isLoading:totalLoading } = useGetTotalFunds();

  useEffect(() => {
    handleToggleSubItem(null);
  }, [pathname]);
  return (
    <Drawer
      autoFocus={false}
      size="md"
      isOpen={isOpen}
      placement="left"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody p={0} overflowY="scroll">
          <Flex justifyContent="space-between" alignItems='center' mt="20px" px="1rem">
            <Image src="/assets/c-logo.svg" />
            <CloseButton onClick={onClose} />
          </Flex>
          <Box mx="20px" mt="20px">
            <Box>
              <Box mt="20px">
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  mb="30px"
                >
                  <Box>
                    <Text color="#929292" fontWeight={600} fontSize="14px">
                      Total Balance
                    </Text>
                    {totalLoading ? (
                      <Spinner />
                    ) : (
                      <Text
                        color="#848484"
                        fontSize={{ base: '1.5rem', md: '2rem' }}
                        fontWeight={700}
                      >
                        {currencyFormat(data?.data) ?? '--'}
                      </Text>
                    )}
                  </Box>
                  <IoIosArrowDropdown fontSize="1.7rem" color=" #8F8F8F" />
                </Flex>

                {siderBarData?.map((item, i) => {
                  return (
                    <VStack key={i} align="stretch" mb=".8rem" gap={0}>
                      <Flex
                        align="center"
                        px={2}
                        py={4}
                        fontWeight={700}
                        color="#5A5A5A"
                        cursor="pointer"
                        onClick={() =>
                          item.sub
                            ? navigate(item.sub[0].path)
                            : navigate(item.path)
                            ? ''
                            : onClose()
                        }
                        bg={
                          pathname.includes(item.path) ||
                          openSubItems[item.name]
                            ? 'rgba(240, 219, 255, 0.66)'
                            : ''
                        }
                        // color={
                        //   pathname.includes(item.path) || openSubItems[item.name]
                        //     ? '#fff'
                        //     : '#f2ea19'
                        // }
                        _hover={{
                          bg: '#f0f4f8',
                          color: '#021603',
                        }}
                        h="45px"
                        borderRadius="10px"
                        transition=".3s ease-in-out"
                        position="relative"
                        gap="24px"
                      >
                        <Box className="hovered_image">{item.hover}</Box>

                        <Box
                          className="initial_image"
                          color={
                            pathname.includes(item.path) ||
                            openSubItems[item.name]
                              ? '#520986'
                              : ''
                          }
                        >
                          {/* {pathname.includes(item.path)
                   
                    ? item.icon } */}
                          {item.icon}
                        </Box>
                        <Box>
                          <Text fontSize="1rem">{item.name}</Text>
                        </Box>

                        {item.sub && (
                          <Flex
                            justifyContent="flex-end"
                            w="full"
                            // color={openSubItems[item.name] ? '#fff' : '#B4B4B4'}
                          >
                            {openSubItems[item.name] ? (
                              <IoIosArrowUp />
                            ) : (
                              <IoIosArrowDown />
                            )}
                          </Flex>
                        )}
                      </Flex>

                      {item.sub && (
                        <Collapse in={openSubItems[item.name]}>
                          <VStack
                            pl={3}
                            align="stretch"
                            borderBottomRadius={4}
                            pb="2"
                            gap={3}
                            pt={4}
                            onClick={onClose}
                          >
                            {item.sub.map((subItem) => (
                              <Link
                                key={subItem.name}
                                to={subItem.path}
                                style={{
                                  padding: '4px 0',
                                  paddingLeft: '30px',
                                  fontSize: '14px',
                                  textDecoration: 'none',

                                  color: pathname.includes(subItem.path)
                                    ? '#5A5A5A'
                                    : '#9C9C9C',
                                  fontWeight: pathname.includes(subItem.path)
                                    ? '700'
                                    : '600',
                                }}
                              >
                                <Flex alignItems="center" gap="5px">
                                  <Box fontSize="1.8rem">{subItem.icon}</Box>

                                  {subItem.name}
                                </Flex>
                              </Link>
                            ))}
                          </VStack>
                        </Collapse>
                      )}
                    </VStack>
                  );
                })}
                <Flex
                  fontWeight={700}
                  px={8}
                  gap="1.5rem"
                  onClick={onClose}
                  mt="5px"
                  alignItems="center"
                >
                  <ImPriceTags />
                  <Link color="#5A5A5A" to="/prices">
                    Prices
                  </Link>
                </Flex>
              </Box>
            </Box>
          </Box>
          <Box
            mt="30px"
            borderTop="1px solid #575757"
            mx="20px"
            fontSize="14px"
            align="center"
            p={2}
            py="30px"
            fontWeight={400}
            cursor="pointer"
            onClick={action}
          >
            {isLoading ? (
              <Flex gap="8px" color="red" align="center">
                <Spinner size="sm" /> Logging Out
              </Flex>
            ) : (
              <Flex
                gap="8px"
                align="center"
                color="#6B3194"
                fontWeight={600}
                fontSize="1.05rem"
                px={2}
              >
                <IoMdLogOut color="f2ea19" />
                Log Out
              </Flex>
            )}
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
export default SideDrawer;
