import { FaHouse } from "react-icons/fa6";
import { FcCurrencyExchange } from "react-icons/fc";
import { LuCircuitBoard } from "react-icons/lu";
import { RiExchangeFundsLine } from "react-icons/ri";
import { FaBitcoin } from "react-icons/fa6";

export const nav = [
  {
    id: "1",
    title: "Home",
    link: "/",
  },
  {
    id: "2",
    title: "Wallet",
    link: "/wallet",
  },
  {
    id: "3",
    title: "About Us",
    link: "/about",
  },
];

export const authNav = [
  {
    id: 1,
    title: "Home",
    link: "/us-dollar",
  },
  {
    id: 2,
    title: "Prices",
    link: "/prices",
  },
  {
    id: 3,
    title: "Earn",
    link: "/earn",
  },
];

export const sideBarData = [
  {
    id: 1,
    name: "Home",
    path: "/us-dollar",
    icon: <FaHouse />,
    sub: [
      {
        id: 1,
        name: "US Dollar",
        icon: <FcCurrencyExchange />,
        path: "/us-dollar",
      },
      {
        id: 2,
        name: "Bitcoin",
        icon: <FaBitcoin />,
        path: "/bitcoin",
      },
    ],
  },
  {
    id: 2,
    name: "Airdrops",
    path: "/airdrops",
    icon: <LuCircuitBoard />,
  },
];

export const heroCoinHeader = ["Name", "Price", "Change", "Chart", "Trade"];
export const heroTableData = [
  {
    id: 1,
    name: "Bitcoin ",
    short: "BTC",
    icon: "assets/bitcoin.svg",
    price: "42,562.60",
    change: "+421.60%",
    success: true,
  },
  {
    id: 2,
    name: "Ethereum",
    short: "ETH",
    icon: "assets/icon-eth.svg",
    price: "2,301.16",
    change: "+9.65%",
    success: true,
  },
  {
    id: 3,
    name: "Tether",
    short: "USDT",
    icon: "assets/icon-usdt.svg",
    price: "100",
    change: "-0.06%",
    success: false,
  },
];

export const test = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
];
export const aboutData = [
  "Seamlessly trade and buy cryptocurrencies using your card or bank account—BTC, ETH, stablecoins, and more",
  "Earn rewards from your cryptocurrency holdings. Put your crypto to work and receive up to 10% in annual rewards.",
  "Take control of your crypto with self-custody. Rest easy, knowing that only you have access to your funds.",
];

export const faqs = [
  {
    id: 1,
    title: "What is Cryptobuytrade",
    text: "Cryptotradebuy is a platform where you experience the confidence to securely buy, sell, store, send, and track your crypto assets with ease. Your gateway to a world of trusted and effortless cryptocurrency management.",
  },
  {
    id: 2,
    title:
      "How secure is Cryptobuytrade for buying and selling cryptocurrencies?",
    text: "Cryptobuytrade is a platform where you experience the confidence to securely buy, sell, store, send, and track your crypto assets with ease. Your gateway to a world of trusted and effortless cryptocurrency management.",
  },
  {
    id: 3,
    title: "How easy is it to send cryptocurrencies on Cryptobuytrade?",
    text: "Cryptobuytrade is a platform where you experience the confidence to securely buy, sell, store, send, and track your crypto assets with ease. Your gateway to a world of trusted and effortless cryptocurrency management.",
  },
  {
    id: 4,
    title: "Can I track my crypto assets on Cryptobuytrade?",
    text: "Cryptobuytrade is a platform where you experience the confidence to securely buy, sell, store, send, and track your crypto assets with ease. Your gateway to a world of trusted and effortless cryptocurrency management.",
  },
  {
    id: 5,
    title:
      " Is Cryptobuytrade suitable for beginners in cryptocurrency management?",
    text: "Cryptobuytrade is a platform where you experience the confidence to securely buy, sell, store, send, and track your crypto assets with ease. Your gateway to a world of trusted and effortless cryptocurrency management.",
  },
];

export const mission = [
  {
    icon: "/assets/mdi_eye.svg",
    title: "OUR VISION",
    text: "To pioneer a revolutionary era in cryptocurrency management where users seamlessly navigate, secure, and optimize their assets with absolute trust and convenience. We envision a future where digital finance is not just accessible but tailored for individual peace of mind and confidence.",
  },
  {
    icon: "/assets/shield.svg",
    title: "OUR MISSION",
    text: "To serve as the ultimate gateway, providing a platform that transforms cryptocurrency management into a seamless and trusted experience. We are dedicated to unlocking the power for users to effortlessly secure, navigate, and optimize their crypto assets.",
  },
];

export const marketCoinsHeader = [
  "Name",
  "Price",
  "Price change %",
  "Market cap",
  "",
];
