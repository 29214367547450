export const PUBLIC_PATHS = {
  LOGIN: '/login',
  REGISTER: '/register',
  LANDING: '/landing',
  FORGOT_PASS: '/forgot-password',
  VERIFY_PASS_OTP: '/verify-password-otp',
  VERIFY_EMAIL: '/verify-email',
  RESET_PASS: '/reset-password',
  WALLET: '/wallet',
  ABOUT: '/about',
};

export const PRIVATE_PATHS = {
  DASHBOARD: '/us-dollar',
  WALLET: '/wallet',
  US_DOLLAR: '/us-dollar',
  BITCOIN: '/bitcoin',
  AIRDROPS: '/airdrops',
  ABOUT: '/about-us',
  PRICES: '/prices',
};
