import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { useState } from 'react';
import { authNav } from '../../common/constant';
import { Link } from 'react-router-dom';
import { CgProfile } from 'react-icons/cg';
import { HiMiniBars3 } from 'react-icons/hi2';
import SideDrawer from './SideDrawer';
import { useGetUser } from '../../../services/query/account';

const Header = () => {
  const [isMobile] = useMediaQuery('(max-width: 999px)');
  const [show, setShow] = useState(false);

  const { data, isLoading } = useGetUser();

  return (
    <Flex className="fixed z-50 w-full items-center py-[30px] justify-between px-6 bg-[#fff]">
      <Flex>
        <Image src="/assets/c-logo.svg" />
        {!isMobile && (
          <Flex
            alignItems="center"
            gap="2rem"
            color="#3D3D3D"
            fontWeight={600}
            ml="20px"
          >
            {authNav?.map((item, i) => (
              <Link key={item.id} to={item.link}>
                {item.title}
              </Link>
            ))}
          </Flex>
        )}
      </Flex>
      {!isMobile && (
        <Flex alignItems="center" gap="20px">
          <Box ml="40px">
            <CgProfile color="#626262" fontSize="2rem" />
          </Box>
          <Box>
            {/* <Text fontWeight={500}>{data?.data?.email ?? '--'}</Text> */}
            <Text fontWeight={500}>
              {' '}
              <span className="text-[#6B3194]">Account id: </span>{' '}
              {data?.data?.userRef ?? '--'}
            </Text>
          </Box>
        </Flex>
      )}
      {isMobile && (
        <HiMiniBars3
          fontSize="2rem"
          onClick={() => {
            setShow(true);
          }}
        />
      )}
      <SideDrawer
        isOpen={show}
        onClose={() => {
          setShow(false);
        }}
      />
    </Flex>
  );
};
export default Header;
