export const LOGIN = 'LOGIN';
export const SIGN_UP = 'SIGN_UP';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const RESEND_VERIFY_EMAIL = 'RESEND_VERIFY_EMAIL';
export const GET_USER = 'GET_USER';
export const SEND_PASS_OTP = 'SEND_PASS_OTP';
export const VERIFY_PASS_OTP = 'VERIFY_PASS_OTP';
export const RESEND_PASS_OTP = 'RESEND_PASS_OTP';
export const CHANGE_PASS = 'CHANGE_PASS';
export const GET_REF_LINK = 'GET_REF_LINK';
export const SEND_REF_INVITE = 'SEND_REF_INVITE';
export const MAKE_PAYMENT = 'MAKE_PAYMENT';
export const GET_PAYMENTS = 'GET_PAYMENTS';
export const GET_TOTAL_FUNDS = 'GET_TOTAL_FUNDS';
export const SEND_WITHDRAW_FUND = 'SEND_WITHDRAW_FUND';